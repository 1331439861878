import {
  Create,
  Loading,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { AdminFaqGroupsWithQuestionsResponseDto } from 'shared/api';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { useHttpClient } from 'shared/hooks/useHttpClient';

export const FaqQuestionsCreate = () => {
  const { data, isLoading } =
    useHttpClient<AdminFaqGroupsWithQuestionsResponseDto>(API_URL.faqGroup);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const faqGroupOptions = data.data.map(({ id, title }) => ({
    id,
    title,
  }));

  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="title"
          label={LABELS.question}
          validate={[required()]}
          fullWidth
        />
        <TextInput
          source="text"
          label={LABELS.answer}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="groupId"
          choices={faqGroupOptions}
          optionText="title"
          fullWidth
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
