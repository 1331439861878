import { TextField, FormControl, Autocomplete, InputAdornment, Typography, RadioGroup, FormControlLabel, } from "@mui/material"
import { DateInput, Form, Show, SimpleShowLayout } from "react-admin"
import React, { FC, useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from "styled-components";
import backend from "shared/backend";
import getTimeFromUTCDate from "shared/utils/get-time-from-utc-date";
import { parseISO, isSameDay } from 'date-fns';
import { iTherapist } from "../../types";

interface Props {

  patientId: string;
  patientFirstname?: string;
  patientLastname?: string;
  patientPhone?: string; 
  patientEmail?: string;

  selectedTherapist: iTherapist | undefined;
  setSelectedTherapist: ( therapist: iTherapist ) => void;

  date: string;
  setDate: ( date: string ) => void;
  setSelectedTime: ( date: string ) => void;

  promoCode: string;
  setPromoCode: ( date: string ) => void;
  isPromoCodeValid: boolean;
  promoMessage: string;
  handleApplyPromoCode: ( date: any ) => void;

  finalPriceMessage: string;
  onBooked: () => void;
  
}

interface iObjectWithIdAndText {

  id: string;
  text: string;

}

export const PatientSessionBooking: FC<Props> = ( props ) => {

  const { 
    
    patientId,
    patientFirstname, 
    patientLastname, 
    patientPhone, 
    patientEmail,

    selectedTherapist,
    setSelectedTherapist,

    date,
    setDate,
    setSelectedTime,

    promoCode,
    setPromoCode,
    promoMessage,
    handleApplyPromoCode,
    isPromoCodeValid,

    finalPriceMessage,
    onBooked, 

  } = props;
  
  const [ therapistsList, setTherapistsList ] = useState<iTherapist[]>([]);
  const [ availableTimes, setAvailableTimes ] = useState<iObjectWithIdAndText[]>([]);
  const [ availabilityId, setAvailabilityId ] = useState<string | undefined>();
  
  async function getTherapistsForSelector() {
    
    const { therapists } = await backend.therapists.list();
    const therapistsForSelector = transformTherapistData( therapists );
    setTherapistsList( therapistsForSelector )

  }

  function transformTherapistData( therapists: any[] ) {

    const result = therapists.map((therapist) => {

      return {

        id: therapist.id,
        name: `${therapist.firstname} ${therapist.lastname}`,
        price: therapist.price

      };

    });

    return result;

  }

  const fetchAvailabilities = async () => {

    const times: any = []

    if ( !selectedTherapist?.id || !date ) { return setAvailableTimes([]); }

    try {

      const response = await backend.therapists.schedule(selectedTherapist.id, date);
      console.log("Fetched Availabilities: ", response);

      if ( !response?.availabilities ) {
        throw new Error( 'Error fetching availability' );
      }

      response.availabilities.forEach(( item: any ) => {

        const isDateMatches = isSameDay( parseISO( date ), parseISO( item.startDate ) );

        if ( !isDateMatches ) { return; }

        times.push({
          
          id: item.id,
          text: getTimeFromUTCDate( item.startDate ),
          
        })

      });

      setAvailableTimes( times );

      console.log("Доступное время: ", times);

    } catch( error ) {

      console.error( 'fetchAvailability', error );

      setAvailableTimes([]);

    }

  }

  useEffect( () => { getTherapistsForSelector() }, []);

  useEffect(() => {

    if ( !date && selectedTherapist ) { return; }
    fetchAvailabilities();

  }, [ date, selectedTherapist ]);

  const handleDateChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {

    setDate( event.target.value );

  };

  const handleTimeChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {

    const timeId = event.target.value;
    const timeText = availableTimes.find(( el ) => el.id === timeId )?.text;

    setAvailabilityId( timeId );
    setSelectedTime( timeText || "" );

  };

  const handleBrone = async () => {

    if ( !patientId || !availabilityId ) {

      throw new Error( 'Не указаны patientId или availabilityId.');

    }

    try {

      const response = await backend.sessions.brone({ 

        patientId, 
        availabilityId, 
        promoCode: isPromoCodeValid ? promoCode : undefined

      });

      if ( !response ) {

        throw new Error( response || 'Произошла ошибка при бронировании.');

      }

      onBooked();

    } catch( error ) {

      console.error('Не удалось забронировать сессию', error );
      return false;

    }

  }

  const applyPromoCode = () => {

    handleApplyPromoCode( promoCode );

    // console.log("где истина", isPromoCodeValid)

    if ( isPromoCodeValid ) {

      // setPromoStatus( "Промокод успешно применен!" );

    } else {

      // setPromoStatus( "Промокод неверный" );
    }
  };
  
  return (

    <Show>

      <BookingWrapper>

        <SimpleShowLayout>

          <TitleBooking>Бронирование сессии для клиента</TitleBooking>

          <div>

            <div>

              Имя:{' '}
              
              {( patientFirstname || patientLastname )

                ? `${ patientFirstname || "" } ${ patientLastname || "" }` 
                : "Отстутвует" 

              }

            </div> 

            <div>Телефон: { patientPhone || "Отстутвует" }</div> 
            <div>Почта: { patientEmail || "Отстутвует" }</div>

          </div>
          
          <Form>  

            <BlocksWrapper>

              <SubtitleBooking variant="h6">
                Выберите или напишите Фамилию терапевта
              </SubtitleBooking>

              <Autocomplete

                fullWidth
                freeSolo 
                style = {{ maxWidth: '400px' }}
                options = { therapistsList }
                isOptionEqualToValue = {( option, value ) => option.id === value.id }
                value = { selectedTherapist }

                onChange = {( event, newValue ) => {

                  setSelectedTherapist( newValue as iTherapist );

                }}

                getOptionLabel = {( option ) => {
                  
                  const o = option as iTherapist;
                  return o?.name;
                
                }}

                renderInput = {( params ) => (

                  <StyledTextField

                    {...params}
                    variant = "outlined"

                    InputProps = {{

                      ...params.InputProps,

                      endAdornment: (

                        <InputAdornment position="end">

                          <ArrowDropDownIcon/>

                        </InputAdornment>

                      ),

                    }}

                  />

                )}
                
              />
                
            </BlocksWrapper>

            { selectedTherapist && (
            
              <>

                <SubtitleBooking variant="h6">Дата сессии</SubtitleBooking>

                <StyledTest

                  fullWidth 
                  label = ""
                  source = "date"
                  defaultValue = {date}
                  onChange = {handleDateChange}

                />
      
                { date !== "" && (

                  <>
              
                    <BlocksWrapper>

                      <FormControl component="fieldset">

                        <SubtitleSessions variant="h6">Время сессии</SubtitleSessions>

                        { availableTimes.length === 0 ? <p>Свободных слотов нет</p> : (

                          <RadioGroup

                            row
                            value = { availabilityId }
                            onChange = { handleTimeChange }
                            sx = {{

                              marginLeft: '12px',
                              columnGap: "8px",
                              rowGap: "12px"
                             
                            }}

                          >

                            { availableTimes.map(( time ) => (

                              <FormControlLabel

                                label = ""
                                key   = { time.id }
                                value = { time.text }

                                control = {

                                  <SquareRadio checked = { availabilityId === time.id }>

                                    { time.text }

                                    <input

                                      type = "radio"
                                      value = { time.id }
                                      checked = { availabilityId === time.id }
                                      onChange = { handleTimeChange }
                                      style = {{ display: 'none' }}

                                    />

                                  </SquareRadio>

                                }

                              />

                            ))}

                          </RadioGroup>

                        )}
                     
                      </FormControl>

                    </BlocksWrapper>

                    { availabilityId && (

                      <>

                        <BlocksWrapper>

                          <SubtitleBooking>Стоимость сессии</SubtitleBooking>
                          <Price>{ selectedTherapist?.price }</Price>

                        </BlocksWrapper>

                        <BlocksWrapper>

                          <SubtitleBooking>Промокод</SubtitleBooking>

                          <InputPromo

                            type = "text"
                            value = { promoCode }
                            onChange = {(event) => setPromoCode(event.target.value)}
                            placeholder = "Введите промокод"
                            disabled = { isPromoCodeValid }
                          
                          />

                          { !isPromoCodeValid &&
                          
                            <ApplyButtonBooking onClick = { applyPromoCode }>
                              Примернить промокод
                            </ApplyButtonBooking>
                          
                          }

                        </BlocksWrapper>

                        <BlocksWrapper>

                          <SubtitleBooking>

                            {promoMessage && <p>{ promoMessage }</p>}
                            {finalPriceMessage && <p>{ finalPriceMessage }{' '}₽</p>}

                          </SubtitleBooking>

                          <ApplyButtonBooking onClick = { handleBrone }>

                            Забронировать сессию

                          </ApplyButtonBooking>

                        </BlocksWrapper>

                      </>

                    )}
                
                  </>

                )}

              </>

            )}

          </Form>

        </SimpleShowLayout>

      </BookingWrapper>

    </Show>

  )

  }

interface SquareRadioProps extends React.HTMLAttributes<HTMLDivElement> {

  checked?: boolean;
  children: React.ReactNode;

}

const SquareRadio = styled('div')<SquareRadioProps>(

  ({ theme, checked }) => ({

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    border: '1px solid',
    borderColor: checked ? '#2196f3' : '#727272',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    backgroundColor: checked ? '#2196f3' : 'transparent',
    color: checked ? '#fff' : '#000',
    padding: '0 10px',

  })
  
);

const BlocksWrapper = styled(Typography)`

  margin-bottom: 14px;

`;

const TitleBooking = styled(Typography)`

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;

`;

const SubtitleBooking = styled(Typography)`

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;

`;

const SubtitleSessions = styled(Typography)`

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-bottom: 10px;

`;

const BookingWrapper = styled(Typography)`

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;

`;

const ApplyButtonBooking = styled.button`

  background-color: #010101;
  border: none;
  border-radius: 0;
  color: #fff;
  padding: 10px 15px;
  margin-top: 9px;
  cursor: pointer; 

`;

const InputPromo = styled.input`

  width: 100%;
  max-width: 400px;
  padding: 10px 12px;
  margin-right: 10px;
  background-color: #f5f5f5;
  border: none;

  &:focus{

    outline: none;

  }

`;

const StyledTest = styled(DateInput)`

  max-width: 400px;

  & .MuiInputBase-root{
  
    &.MuiFilledInput-root{

      &::before{

        border: none;

      }
    
    }

    & div {

     user-select: none;

    }
  }

  input{

    padding-top: 14px;
    padding-right: 4px;
    padding-bottom: 12px;
    padding-left: 12px;
    border-radius: 0;
    border: 1px solid #fff;

  }

  #date-helper-text{

    margin-bottom:-20px;
  
  }

`;

const StyledTextField = styled(TextField)`

  & .MuiOutlinedInput-root {

    background-color: #f5f5f5;

    & fieldset {

      border-color: #fff;
      
    }

    &:hover fieldset {

      border-color: #fff;

    }

    &.Mui-focused fieldset {

      border-color: #fff;

    }

    &.MuiInputBase-sizeSmall{

      padding-top: 8px;
      padding-right: 4px;
      padding-bottom: 6px;
      padding-left: 10px;
      border-radius: 0;
      
    }

  }

`;


const Price =  styled(Typography)`

  margin-top: 6px;

`;