import { API_BASE_URL } from "shared/config";
import { API_URL } from "shared/constants/api-url";
import { httpClient } from "shared/services/http-client";
import objectToQueryParams from "shared/utils/object-to-query-params";

async function list() {

  try { 
    
    const params = {

      limit: 100,
      page: 1,
      debug: true

    }

    const apiUrl = (

      `${ API_BASE_URL }/${ API_URL.therapistsList }`
      +`?${ objectToQueryParams( params ) }`

    );


    const { json } = await httpClient( apiUrl, { method: 'GET' });

    if ( !json ) {
      throw new Error( 'Произошла ошибка при получении данных' );
    }

    return { 

      ...json,
      therapists: json.data,
      data: undefined
      
    }

  } catch ( err ) {

    alert( err );

  }

}

async function schedule(therapistId: string, date: string) {

  const apiUrl = `${API_BASE_URL}/therapists/${therapistId}/availability`;

  try {

    const { json } = await httpClient( apiUrl, { method: 'GET' } );

    if (!json) throw new Error('Произошла ошибка при получении данных');

    return json;

  } catch (err) {

    console.error(err);
    throw err;

  }

}

export {

  list,
  schedule 

}