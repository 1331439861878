import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { Button } from '@mui/material';
import { useToggleIsB2B } from 'features/toggle-is-b2b/model';
import { FC } from 'react';

interface Props {
  id?: string;
  isB2B?: boolean;
  isB2BUrl: string;
}

export const ToggleIsB2B: FC<Props> = ({ isB2B, isB2BUrl, id }) => {
  const { handleToggleIsB2B, isLoading } = useToggleIsB2B({
    isB2B,
    id,
    isB2BUrl,
  });

  return (
    <Button
      variant="contained"
      color={isB2B ? 'success' : 'error'}
      startIcon={isB2B ? <CheckCircleIcon /> : <UnpublishedIcon />}
      size="small"
      onClick={handleToggleIsB2B}
      disabled={isLoading}
    >
      B2B
    </Button>
  );
};
