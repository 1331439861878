import { Button, Link } from '@mui/material';
import { FC } from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  EmailField,
  FileField,
  FunctionField,
  ImageField,
  NumberField,
  Tab,
  TabbedShowLayout,
  TextField,
  WrapperField,
} from 'react-admin';
import {
  AdminTherapistResponseDto,
  SupervisionRequestResponseDtoTypeEnum,
} from 'shared/api';
import { API_URL } from 'shared/constants/api-url';
import { LABELS } from 'shared/constants/labels';
import { getGender } from 'shared/utils/get-gender';
import { getLanguages } from 'shared/utils/get-languages';
import { getReligions } from 'shared/utils/get-religions';
import { getSpecializations } from 'shared/utils/get-specialization';
import { getSupervisionType } from 'shared/utils/get-supervision-type';
import { getValueFromTextareaWithLineBreak } from 'shared/utils/get-value-from-textarea-with-line-break';
import { isEmptyObject } from 'shared/utils/is-empty-object';
import {
  useAbout,
  useRewardInPercent,
  useSessionPrice,
  useShortAbout,
} from 'widgets/therapists/show/model';
import './content.css';
import TherapistSessions from './sessions';
import TherapistB2BSessions from './b2b-sessions';
import Statistics from './statistics';
import { getWorkApproach } from 'shared/utils/get-work-approach';

interface Props {
  data?: AdminTherapistResponseDto;
}

export const TherapistShowContent: FC<Props> = ({ data }) => {
  const updRewardInPercent = useRewardInPercent({
    id: data?.id,
    rewardInPercentUrl: API_URL.therapistUpdateRewardInPercent,
    currentPercent: data?.rewardInPercent ?? 0,
  });

  const updSessionPrice = useSessionPrice({
    id: data?.id,
    priceUrl: API_URL.therapistUpdateSessionPrice,
    currentPrice: data?.workingInfo?.price ?? 0,
  });

  const updShortAbout = useShortAbout({
    id: data?.id,
    shortAboutUrl: API_URL.therapistUpdateShortAbout,
    shortAbout: data?.personalInfo?.shortAbout ?? '',
  });

  const updAbout = useAbout({
    id: data?.id,
    aboutUrl: API_URL.therapistUpdateAbout,
    about: data?.personalInfo?.about ?? '',
  });

  return (
    <TabbedShowLayout
      sx={{
        [`.MuiTabs-flexContainer[role=tablist]`]: {
          flexWrap: 'wrap',
        }
      }}
    >
      <Tab label="Основная информация">
        <EmailField source="user.email" label={LABELS.email} />
        {data?.user.phone && (
          <WrapperField label={LABELS.phone}>
            <Link href={`tel:${data?.user.phone}`}>{data?.user.phone}</Link>
          </WrapperField>
        )}

        <TextField source="personalInfo.firstname" label={LABELS.firstname} />
        <TextField source="personalInfo.lastname" label={LABELS.lastname} />
        <TextField
          source="rewardInPercent"
          label={LABELS.rewardInPercent}
          onClick={updRewardInPercent.handleToggle}
          className={'rewardInPercent'}
        />
        <TextField source="personalInfo.country" label={LABELS.country} />
        <TextField source="personalInfo.city" label={LABELS.city} />
        <FunctionField
          label={LABELS.gender}
          render={(record: AdminTherapistResponseDto) =>
            getGender(record.personalInfo.gender)
          }
        />
        <TextField
          source="personalInfo.shortAbout"
          label={LABELS.shortAbout}
          className={'rewardInPercent'}
          onClick={updShortAbout.handleToggle}
        />

        <Button
          sx={{ maxWidth: 139, fontSize: '11px' }}
          variant="contained"
          color="info"
          onClick={updShortAbout.handleToggle}
        >
          редактировать
        </Button>

        <FunctionField
          label={LABELS.about}
          render={(record: AdminTherapistResponseDto) =>
            getValueFromTextareaWithLineBreak(record.personalInfo.about)
          }
        />

        <Button
          sx={{ maxWidth: 139, fontSize: '11px' }}
          variant="contained"
          color="info"
          onClick={updAbout.handleToggle}
        >
          редактировать
        </Button>

        {data?.personalInfo.avatarFilekey && (
          <WrapperField label={LABELS.avatarKey}>
            <a
              href={data?.personalInfo.avatarFilekey}
              target="_blank"
              rel="noreferrer"
            >
              <ImageField
                source="personalInfo.avatarFilekey"
                sx={{
                  '& .RaImageField-image': {
                    width: 300,
                    height: 300,
                    objectFit: 'cover',
                  },
                }}
              />
            </a>
          </WrapperField>
        )}

        {data?.personalInfo.videoFilekey && (
          <WrapperField label="Видео-презентация">
            <video src={data?.personalInfo.videoFilekey} controls playsInline />
          </WrapperField>
        )}
      </Tab>
      <Tab label="Опыт работы">
        <FunctionField
          label={LABELS.workApproach1}
          render={(record: AdminTherapistResponseDto) =>
            getWorkApproach(record?.workingInfo?.workApproach1)
          }
        />
        <FunctionField
          label={LABELS.workApproach2}
          render={(record: AdminTherapistResponseDto) =>
            getWorkApproach(record?.workingInfo?.workApproach2)
          }
        />
        <FunctionField
          label={LABELS.workApproach3}
          render={(record: AdminTherapistResponseDto) =>
            getWorkApproach(record?.workingInfo?.workApproach3)
          }
        />
        <NumberField
          source="workingInfo.price"
          label={LABELS.price}
          onClick={updSessionPrice.handleToggle}
          className={'rewardInPercent'}
        />
        <FunctionField
          label={LABELS.languages}
          render={(record: AdminTherapistResponseDto) =>
            getLanguages(record.workingInfo.languages)
          }
        />
        <FunctionField
          label={LABELS.workWithReligions}
          render={(record: AdminTherapistResponseDto) =>
            record.workingInfo.workWithReligions &&
            getReligions(record.workingInfo.workWithReligions)
          }
        />
        <NumberField
          source="workingInfo.devoteHoursToPlatform"
          label={LABELS.devoteHoursToPlatform}
        />
        <NumberField
          source="workingInfo.numberOfPatients"
          label={LABELS.numberOfPatients}
        />
        <BooleanField
          source="workingInfo.workWithIndividual"
          label={LABELS.workWithIndividual}
        />
        <BooleanField
          source="workingInfo.workWithClinical"
          label={LABELS.workWithClinical}
        />
        <BooleanField
          source="workingInfo.workWithCheckup"
          label={LABELS.workWithCheckup}
        />
        <BooleanField
          source="workingInfo.workWithCouples"
          label={LABELS.workWithCouples}
        />
        <BooleanField
          source="workingInfo.workWithFamilies"
          label={LABELS.workWithFamilies}
        />
        <BooleanField
          source="workingInfo.workWithGroups"
          label={LABELS.workWithGroups}
        />
        <BooleanField
          source="workingInfo.workWithChildren"
          label={LABELS.workWithChildren}
        />
        <BooleanField
          source="workingInfo.workWithLgbt"
          label={LABELS.workWithLgbt}
        />
        <NumberField
          source="workingInfo.workingExperience"
          label={LABELS.workingExperience}
        />
        <FunctionField
          label={LABELS.workingExperienceDescription}
          render={(record: AdminTherapistResponseDto) =>
            getValueFromTextareaWithLineBreak(
              record.workingInfo.workingExperienceDescription,
            )
          }
        />
        <FunctionField
          label={LABELS.ethnicExperienceDescription}
          render={(record: AdminTherapistResponseDto) =>
            getValueFromTextareaWithLineBreak(
              record.workingInfo.ethnicExperienceDescription,
            )
          }
        />
      </Tab>
      <Tab label="Образование">
        <ArrayField source="educationInfo.education" label="Высшее образование">
          <Datagrid bulkActionButtons={false}>
            <TextField source="university" label={LABELS.university} />
            <TextField source="speciality" label={LABELS.speciality} />
            <FileField
              source="files"
              src="key"
              title="filename"
              target="_blank"
              label={LABELS.files}
            />
          </Datagrid>
        </ArrayField>
        <ArrayField
          source="educationInfo.additionalEducation"
          label="Дополнительное образование"
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="organisation" label={LABELS.organisation} />
            <TextField source="course" label={LABELS.course} />
            <FileField
              source="files"
              src="key"
              title="filename"
              target="_blank"
              label={LABELS.files}
            />
          </Datagrid>
        </ArrayField>
      </Tab>
      {data?.recommendationInfo && !isEmptyObject(data?.recommendationInfo) && (
        <Tab label="Рекомендации">
          {data?.recommendationInfo.recommendationText && (
            <FunctionField
              label={LABELS.recommendationText}
              render={(record: AdminTherapistResponseDto) =>
                getValueFromTextareaWithLineBreak(
                  record.recommendationInfo.recommendationText,
                )
              }
            />
          )}
          {data?.recommendationInfo.recommendationFiles && (
            <FileField
              source="recommendationInfo.recommendationFiles"
              src="key"
              title="filename"
              target="_blank"
              label={LABELS.recommendationFiles}
            />
          )}
        </Tab>
      )}
      <Tab label="Супервизии">
        <BooleanField
          source="supervisionInfo.isSupervisor"
          label={LABELS.isSupervisor}
        />
        {data?.supervisionInfo.supervisionExperience && (
          <NumberField
            source="supervisionInfo.supervisionExperience"
            label={LABELS.supervisionExperience}
          />
        )}

        {data?.supervisionInfo.therapistSupervisionInfo &&
          data?.supervisionInfo.therapistSupervisionInfo
            .supervisionFrequency && (
            <TextField
              source="supervisionInfo.therapistSupervisionInfo.supervisionFrequency"
              label={LABELS.supervisionFrequency}
            />
          )}
        {data?.supervisionInfo.therapistSupervisionInfo &&
          data?.supervisionInfo.therapistSupervisionInfo.supervisionType && (
            <FunctionField
              label={LABELS.supervisionType}
              render={(record: AdminTherapistResponseDto) =>
                getSupervisionType(
                  record.supervisionInfo.therapistSupervisionInfo
                    .supervisionType as SupervisionRequestResponseDtoTypeEnum[],
                )
              }
            />
          )}
        {data?.supervisionInfo.therapistSupervisionInfo &&
          data?.supervisionInfo.therapistSupervisionInfo
            .supervisionVisitsDuration && (
            <NumberField
              source="supervisionInfo.therapistSupervisionInfo.supervisionVisitsDuration"
              label={LABELS.supervisionVisitsDuration}
            />
          )}
        {data?.supervisionInfo.therapistSupervisionInfo &&
          data?.supervisionInfo.therapistSupervisionInfo
            .supervisionExperienceDescription && (
            <FunctionField
              label={LABELS.supervisionExperienceDescription}
              render={(record: AdminTherapistResponseDto) =>
                getValueFromTextareaWithLineBreak(
                  record.supervisionInfo.therapistSupervisionInfo
                    .supervisionExperienceDescription,
                )
              }
            />
          )}
      </Tab>
      <Tab label="Терапия">
        <FunctionField
          label={LABELS.specialisation}
          render={(record: AdminTherapistResponseDto) =>
            record.therapiesInfo.specialisation
              .map((item) => item && getSpecializations(item))
              .join(', ')
          }
        />
        <FunctionField
          label={LABELS.therapistProfileDiseases}
          render={(record: AdminTherapistResponseDto) =>
            record.therapiesInfo.diseases
              .map((item) => (item ? item.name : item))
              .join(', ')
          }
        />
        <FunctionField
          label={LABELS.therapistProfileExcludedDiseases}
          render={(record: AdminTherapistResponseDto) =>
            record.therapiesInfo.excludedDiseases
              .join(', ')
          }
        />
        <FunctionField
          label={LABELS.therapistProfileTherapyMethods}
          render={(record: AdminTherapistResponseDto) =>
            record.therapiesInfo.therapyMethods
              .map((item) => (item ? item.name : item))
              .join(', ')
          }
        />
        <BooleanField
          source="therapiesInfo.workWithPsychiatristExperience"
          label={LABELS.workWithPsychiatristExperience}
        />
        <FunctionField
          label={LABELS.psychiatristCollaboration}
          render={(record: AdminTherapistResponseDto) =>
            getValueFromTextareaWithLineBreak(record.therapiesInfo.psychiatristCollaboration)
          }
        />
      </Tab>
      <Tab label="Медийность">
        <BooleanField
          source="collaborationInfo.workshopExperience"
          label={LABELS.workshopExperience}
        />
        <BooleanField
          source="collaborationInfo.workshopDesire"
          label={LABELS.workshopDesire}
        />
        <BooleanField
          source="collaborationInfo.blogMaterials"
          label={LABELS.blogMaterials}
        />
        <BooleanField
          source="collaborationInfo.specialProjects"
          label={LABELS.specialProjects}
        />
        <FunctionField
          label={LABELS.socialsLinks}
          render={(record: AdminTherapistResponseDto) =>

            data?.collaborationInfo?.socialsLinks?.length && data?.collaborationInfo.socialsLinks.map(( el:string, key) => <div key={key}>{el}</div> )
          }
        />
        <BooleanField
          source="collaborationInfo.promotionSupport"
          label={LABELS.promotionSupport}
        />
        <FunctionField
          label={LABELS.materialsLinks}
          render={(record: AdminTherapistResponseDto) =>

            data?.collaborationInfo?.materialsLinks?.length && data?.collaborationInfo.materialsLinks.map(( el:string, key) => <div key={key}>{el}</div> )
          }
        />
      </Tab>
      <Tab label="Статистика">
        {data?.id ? <Statistics therapistId={data.id} /> : <>Ошибка</>}
      </Tab>
      <Tab label="Сессии">
        {data?.id ? <TherapistSessions therapistId={data.id} /> : <>Ошибка</>}
      </Tab>
      <Tab label="B2B сессии">
        {data?.id ? <TherapistB2BSessions therapistId={data.id} /> : <>Ошибка</>}
      </Tab>
    </TabbedShowLayout>
  );
};
