import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNotify } from 'react-admin';
import Papa from 'papaparse';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { parse, isValid } from 'date-fns';

const MAX_FILE_SIZE = 100 * 1024 * 1024;
const MAX_ROWS = 1000;

const ONE_YEAR_FROM_NOW = new Date();
ONE_YEAR_FROM_NOW.setFullYear(ONE_YEAR_FROM_NOW.getFullYear() + 1);

const UploadPatients = () => {
  const { id } = useParams();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  const clearFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = '';
  };

  const isEmptyRow = (row: string[]) => {
    return row.every(cell => cell.trim() === '');
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!id) {
      notify('id компании не определен', { type: 'error' });
      return clearFile(event);
    }

    const file = event.target.files?.[0];
    
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      notify('Размер файла превышает 100 МБ', { type: 'error' });
      return clearFile(event);
    }

    setIsLoading(true);

    try {
      const results = await new Promise<string[][]>((resolve, reject) => {
        Papa.parse(file, {
          complete: (results) => {
            if (results.data.length > MAX_ROWS) {
              reject('Превышено максимальное количество строк (1000)');
              return;
            }
            resolve(results.data as string[][]);
          },
          error: (error) => reject(error.message),
        });
      });

      const headers = results[0].map(header => header.toLowerCase().trim());
      const emailIndex = headers.indexOf('email');
      const roleIndex = headers.indexOf('role');
      const groupIndex = headers.indexOf('group');
      const balanceIndex = headers.indexOf('balance');
      const balanceStartWithIndex = headers.indexOf('balancestartwith');
      const balanceEndWithIndex = headers.indexOf('balanceendwith');

      const indices = {
        emailIndex,
        roleIndex,
        balanceIndex,
        balanceStartWithIndex,
        balanceEndWithIndex,
      };

      const columnLabels: Record<string, string>  = {
          emailIndex: "email",
          roleIndex: "role",
          balanceIndex: "balance",
          balanceStartWithIndex: "balanceStartWith",
          balanceEndWithIndex: "balanceEndWith",
      };

     const missingColumns = Object.entries(indices)
         .filter(([_, value]) => value === -1)
         .map(([key]) => columnLabels[key]);

      if (missingColumns.length > 0) {
        notify(
            `Не найдены колонки: ${missingColumns.join(', ')}`,
            { type: 'error' }
        );
        return clearFile(event);
      }

      const data = results
        .slice(1)
        .filter(row => !isEmptyRow(row))
        .map((row, index) => {
          let strNumber = index + 2

          const email= row[emailIndex]?.trim()
          if (email === "") {
            throw new Error(
              `Ошибка валидации email: ${email}. Значение не может быть пустым (строка №${strNumber})`
            );
          }

          const role = row[roleIndex]?.trim()
          if (role === "") {
            throw new Error(
              `Ошибка валидации role: ${role}. Значение не может быть пустым (строка №${strNumber})`
            );
          }

          const sessionBalanceRaw = row[balanceIndex]?.trim();
          const sessionBalance = Number(sessionBalanceRaw);

          if (isNaN(sessionBalance) || !Number.isInteger(sessionBalance) || sessionBalance < 1 || sessionBalance > 10) {
            throw new Error(
                `Ошибка валидации balance: ${sessionBalanceRaw}. Должно быть целым числом от 1 до 10. (строка №${strNumber})`
            )
          }

          const sessionBalanceStartWithRaw = row[balanceStartWithIndex]?.trim();
          const sessionBalanceEndWithRaw = row[balanceEndWithIndex]?.trim();


          const sessionBalanceStartWith = parse(sessionBalanceStartWithRaw, 'yyyy-MM-dd', new Date());
          if (!isValid(sessionBalanceStartWith)) {
             throw new Error(`Ошибка валидации в balanceStartWith: ${sessionBalanceStartWithRaw}. Неверный формат даты. (строка №${strNumber})`);
          }

          const sessionBalanceEndWith = parse(sessionBalanceEndWithRaw, 'yyyy-MM-dd', new Date());
          if (!isValid(sessionBalanceEndWith)) {
             throw new Error(`Ошибка валидации в balanceEndWith: ${sessionBalanceEndWith}. Неверный формат даты. (строка №${strNumber})`);
          }

          if (sessionBalanceStartWith < new Date(Date.now())) {
            throw new Error(`Ошибка валидации: balanceStartWith (${sessionBalanceStartWithRaw}) должна быть больше текущей даты. (строка №${strNumber})`)
          }

          if (sessionBalanceStartWith >= sessionBalanceEndWith) {
            throw new Error(`Ошибка валидации: balanceStartWith (${sessionBalanceStartWithRaw}) должна быть раньше sessionBalanceEndWith (${sessionBalanceEndWithRaw}). (строка №${strNumber})`)
          }

          if (sessionBalanceStartWith > ONE_YEAR_FROM_NOW) {
            throw new Error(`Ошибка: balanceStartWith (${sessionBalanceStartWithRaw}) не может быть позже, чем через 1 год. (строка №${strNumber})`);
          }

          if (sessionBalanceEndWith > ONE_YEAR_FROM_NOW) {
            throw new Error(`Ошибка: balanceEndWith (${sessionBalanceEndWithRaw}) не может быть позже, чем через 1 год. (строка №${strNumber})`);
          }

          const hasCyrillic = /[а-яА-ЯёЁ]/.test(row[emailIndex]);
          const hasLatin = /[a-zA-Z]/.test(row[emailIndex]);
          if (hasCyrillic && hasLatin) {
              throw new Error(`Ошибка: email (${row[emailIndex]}) содержит недопустимую смесь латинских и кириллических символов. (строка №${strNumber})`);
          }

          return {
            email: email,
            role: role,
            group: (groupIndex !== -1 && row[groupIndex] !== "") ? row[groupIndex]?.trim() : undefined,
            sessionBalance: sessionBalance,
            sessionBalanceStartWith: sessionBalanceStartWith,
            sessionBalanceEndWith: sessionBalanceEndWith,
          };
        })

      // Проверка на дубликаты email
      const emails = data.map(item => item.email.toLowerCase());
      const duplicateEmails = emails.filter((email, index) => emails.indexOf(email) !== index);
      
      if (duplicateEmails.length > 0) {
        notify(`Найдены дублирующиеся email адреса: ${duplicateEmails.join(', ')}`, { type: 'error' });
        return clearFile(event);
      }

      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.uploadCompanyPatients(id)}`, {
        method: 'POST',
        body: JSON.stringify({ patients: data }),
      });

      notify('Данные успешно загружены', { type: 'success' });

    } catch (error) {
      notify(error instanceof Error ? error.message : 'Произошла ошибка при обработке файла', {
        type: 'error',
      });

      return clearFile(event);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h3>Загрузка сотрудников</h3>
      <p>Загрузите CSV файл со списком сотрудников. Файл должен содержать колонки: email, role, balance, balanceStartWith, balanceEndWith.</p>
      <p>Ограничения:</p>
      <ul>
        <li>Максимальный размер файла: 100 МБ</li>
        <li>Максимальное количество строк: 1000</li>
        <li>Колонка balance: максимальное значение - 10, минимальное - 0</li>
        <li>Колонка balanceStartWith (формат Y-m-d): должна содержать значение даты, не более чем на год вперед (Например: 2025-06-20) </li>
        <li>Колонка balanceEndWith (формат Y-m-d): должна быть не меньше текущей даты и больше, чем значение даты в balanceStartWith (Например: 2025-07-20)</li>
        <li>Обязательные колонки: email, role, balance, balanceStartWith, balanceEndWith</li>
        <li>Опциональные колонки: group</li>
      </ul>
      <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        disabled={isLoading}
        style={{ marginTop: '20px' }}
      />
      {isLoading && <div>Загрузка...</div>}
    </div>
  );
};

export default UploadPatients;
