import { FC, useState } from 'react';
import {
  Datagrid,
  DateField,
  EmailField,
  ListContextProvider,
  Pagination,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useList,
} from 'react-admin';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import { API_URL } from 'shared/constants/api-url';
import { AdminB2BPaginatedPatientsResponseDto } from 'shared/api';
import { LABELS } from 'shared/constants/labels';

interface Props {
  companyId: string;
}

const authorizationChoices = [
  { id: 'null', name: 'Не имеет значения' },
  { id: 'true', name: 'Да' },
  { id: 'false', name: 'Нет' },
];

const CompanyPatients: FC<Props> = ({ companyId }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchEmail, setSearchEmail] = useState('');
  const [isAuthorized, setIsAuthorized] = useState('null');
  const { data, isLoading } = useHttpClient<AdminB2BPaginatedPatientsResponseDto>(
    `${API_URL.companyPatients(companyId)}?limit=${perPage}&page=${page}${!!searchEmail ? `&email=${encodeURIComponent(searchEmail)}` : ''}${isAuthorized !== 'null' ? `&isAuthorized=${isAuthorized}` : ''}`,
  );

  const listContext = useList({
    data: data?.data ?? [],
    isLoading,
    page,
    perPage,
    sort: { field: 'lastname', order: 'DESC' },
  });

  const handleUpdateSearchEmail = (value: string) => {
    console.log(value);
    setSearchEmail(value);
    setPage(1);
  };

  const handleAuthorizeChange = (value: string) => {
    console.log(value);
    setIsAuthorized(value);
    setPage(1);
  };

  return (
    <>
    <SimpleForm toolbar={false}>
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-end' }}>
      <TextInput source="email" alwaysOn title='Email' placeholder='Email' value={searchEmail} onChange={(e) => handleUpdateSearchEmail(e.target.value)} />
      <SelectInput
            source="isAuthorized"
            label="Вошел в ЛК"
            defaultValue={isAuthorized}
            choices={authorizationChoices}
            value={isAuthorized}
            onChange={(e) => handleAuthorizeChange(e.target.value)}
          />
      </div>
    </SimpleForm>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <TextField
            source="firstname"
            sortable={false}
            label={LABELS.firstname}
          />
          <TextField source="lastname" label={LABELS.lastname} />
          <EmailField source="email" label={LABELS.email} sortable={false} />
          <TextField source="companyGroup" label={LABELS.companyGroup} sortable={false} />
          <TextField source="companyRole" label={LABELS.companyRole} sortable={false} />
          <DateField
            source="dateOfBirth"
            label={LABELS.dateOfBirth}
            sortable={false}
          />
          <DateField
            source="createdAt"
            label="Зарегистрирован"
            sortable={false}
            showTime
          />
        </Datagrid>
        <Pagination
          isLoading={isLoading}
          page={page}
          perPage={perPage}
          total={data?.total}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      </ListContextProvider>
    </>
  );
};

export default CompanyPatients;
