import {
  Loading,
  useGetOne,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { PatientInfo } from './ui/info';
import { PatientSessionBooking } from './ui/session-booking';
import { PatientSessionBooked } from './ui/session-booked';
import { useState } from 'react';
import { iTherapist } from './types';
import prettyFloat from "shared/utils/pretty-float";
import backend from "shared/backend";



interface Patient {

  id: string;
  userId: string;
  isTest?: boolean;
  isCard?: boolean;
  paymentMethodId?: string;
  deletedAt?: string;
  avatarKey?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  email?: string;

}

export const PatientShow = () => {

  const PAGE = {

    INFO:    1,
    BOOKING: 2,
    BOOKED:  3

  }

  const { id } = useParams<{ id: any }>();
  const redirect = useRedirect();
  const [ selectedTherapist, setSelectedTherapist ] = useState<iTherapist>();
  const [ date, setDate ] = useState( "" );
  const [ promoCode, setPromoCode ] = useState('');
  const [ selectedTime, setSelectedTime ] = useState<string>('');


  const [ promoMessage, setPromoMessage ] = useState<string>('');
  const [ finalPrice, setFinalPrice ] = useState(0);
  const [ finalPriceMessage, setFinalPriceMessage ] = useState('');
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);


  const [ currentPage, setCurrentPage ] = useState( PAGE.INFO );
  const { data: patientData, isLoading: isPatientLoading, error: patientError } = useGetOne<Patient>(
    'patients',
    { id },
    { onError: () => redirect('/patients') },
  );
  
  
  const handleApplyPromoCode = async () => {

    try {

      if ( !patientData?.userId ) {
        throw new Error( 'Отсутствует patientData?.userId' );
      }

      const response = await backend.promocode.check( promoCode, patientData.userId );

      if ( response ) {

        const { amount, percentage, expireDate } = response;
        const currentDate = new Date();
        const expirationDate = new Date( expireDate );

        if ( expirationDate < currentDate ) {

          setPromoMessage( 'Промокод истек.' );
          setFinalPriceMessage( '' );
          return;

        }

        let discount = 0;

        if ( amount ) {

          discount = amount;

        } else if ( percentage ) {

          discount = ( selectedTherapist?.price ?? 0 ) * ( percentage / 100 );

        }

        const final = prettyFloat( ( selectedTherapist?.price ?? 0 ) - discount );
        
        setPromoMessage( 'Промокод применён.' );
        setIsPromoCodeValid( true );
        setFinalPriceMessage( `Итоговая стоимость с учетом промокода: ${(final > 0 ? final : 0)}` );
        setFinalPrice( final );

      } else {
        
        setPromoMessage( 'Промокод недействителен.' );
        setFinalPriceMessage( '' );

      }

    } catch ( error ) {

      setPromoMessage( 'Произошла ошибка при применении промокода.' );
      setFinalPriceMessage( '' );

    }

  }
  
  if ( isPatientLoading ) {
    return <Loading />;
  }
  
  if ( patientError ) {
    return (
      <p>
        Произошла ошибка при загрузке данных: 
        {/* {patientError ? patientError.message : therapistError.message} */}
      </p>
    );
  }

  if ( !patientData ) {
    return <p>Данные отсутствуют.</p>;
  }

  const RENDER = {

    [ PAGE.INFO ]: (

      <PatientInfo 

        id = { id } 
        isTest = { patientData.isTest ?? false }
        isBanned = { !!patientData?.deletedAt }
        isCard = { !!patientData?.isCard }
        avatarKey = { patientData?.avatarKey }
        onBookSession = { () => setCurrentPage( PAGE.BOOKING ) }

      />

    ),

    [ PAGE.BOOKING ]: (

      <PatientSessionBooking 

        patientId = { patientData?.id }
        patientFirstname = { patientData?.firstname }
        patientLastname = { patientData?.lastname }
        patientPhone = { patientData?.phone }
        patientEmail = { patientData?.email }

        selectedTherapist = { selectedTherapist }
        setSelectedTherapist = { setSelectedTherapist }

        date = { date }
        setDate = { setDate }
        setSelectedTime = { setSelectedTime }

        promoCode = { promoCode }
        setPromoCode = { setPromoCode }
        isPromoCodeValid = { isPromoCodeValid }
        promoMessage = { promoMessage }
        handleApplyPromoCode = { handleApplyPromoCode }

        finalPriceMessage = { finalPriceMessage }
        onBooked = { () => setCurrentPage( PAGE.BOOKED ) }

      />

    ),

    [ PAGE.BOOKED ]: (

      <PatientSessionBooked 

        onBack = { () => setCurrentPage( PAGE.INFO ) }
        patientFirstname = { patientData?.firstname }
        patientLastname = { patientData?.lastname }
        patientPhone = { patientData?.phone }
        patientEmail = { patientData?.email }
        selectedTherapist = { selectedTherapist }
        date = { date }
        promoCode = { promoCode }
        selectedTime = { selectedTime }
        finalPrice = { finalPrice }
        isPromoCodeValid = { isPromoCodeValid }
        
      />

    ),

  }

  return RENDER[ currentPage ];
  
};