import { useRecordContext } from 'react-admin';
import { AdminSessionResponseDto, AdminSessionResponseDtoStatusEnum } from 'shared/api';
import { CancelSessionPayment } from 'features/cancel-session-payment';
import { SetSessionPaymentPaid, SetSessionPromocodePaid } from 'features/set-session-payment-paid';
import { FC } from 'react';

interface Props {
  refetch?: () => void;
}

const REFRESH_DELAY = 1000;

const RowActions: FC<Props> = ({ refetch }) => {
  const record = useRecordContext<AdminSessionResponseDto>();

  const refresh = async () => {
    await new Promise((resolve) => {
      setTimeout(() => {
        refetch?.();
        resolve(true);
      }, REFRESH_DELAY);
    });


  };

  return (
    <>
      {record.status === AdminSessionResponseDtoStatusEnum.Awaiting && (
        <CancelSessionPayment sessionId={record.id} onSuccess={refresh} />
      )}
      {record.payment.discountValue > 0 && !record.payment.isPromocodePaid && (record.payment.payoutTerminal !== 'manual' || record.payment.value === 0) && (
        <SetSessionPromocodePaid sessionId={record.id} onSuccess={refetch} />
      )}
      {record.payment.status === 'succeeded' &&  record.payment.payoutStatus === 'awaiting' && record.payment.payoutTerminal === 'manual' && (
        <SetSessionPaymentPaid sessionId={record.id} onSuccess={refetch} />
      )}
    </>
  );
};
export default RowActions;
