import { useCallback } from 'react';
import { useRefresh, useUpdate } from 'react-admin';

interface Params {
  id?: string;
  isB2B?: boolean;
  isB2BUrl: string;
}

export const useToggleIsB2B = ({ isB2B, isB2BUrl, id }: Params) => {
  const refresh = useRefresh();

  const [toggle, { isLoading, error }] = useUpdate(
    isB2BUrl,
    {
      id,
      data: { isB2B: !isB2B },
    },
    {
      onSettled: () => {
        refresh();
      },
    },
  );

  const handleToggleIsB2B = useCallback(() => {
    toggle();
  }, [toggle]);

  return {
    toggle,
    handleToggleIsB2B,
    isLoading,
    error,
  };
};
