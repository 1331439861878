import { DateTimeInput, Form } from 'react-admin';
import styled from 'styled-components';
import { Fragment, useEffect, useState } from 'react';
import { StatHead, StatRow } from './ui/stat-row';
import { httpClient } from 'shared/services/http-client';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';

const now = new Date();
const defaultStartDate = new Date(
  now.getFullYear(),
  now.getMonth() - 1,
).toISOString();
const defaultEndDate = new Date().toISOString();

const ClientsStatistics = () => {

  const [ isLoading, setIsLoading ] = useState(false);

  const [ startDate, setStartDate ] = useState( defaultStartDate );
  const [ endDate, setEndDate ] = useState( defaultEndDate );

  const [ totalClientsMedsi, setTotalClientsMedsi ] = useState(0);
  const [ totalClientsPogovorim, setTotalClientsPogovorim ] = useState(0);

  const [ totalSessionsMedsi, setTotalSessionsMedsi ] = useState(0);
  const [ totalSessionsPogovorim, setTotalSessionsPogovorim ] = useState(0);

  const [ firstTimeClientMedsi, setFirstTimeClientMedsi ] = useState(0);
  const [ firstTimeClientPogovorim, setFirstTimeClientPogovorim ] = useState(0);

  const [ newClientsMedsi, setNewClientsMedsi ] = useState(0);
  const [ newClientsPogovorim, setNewClientsPogovorim ] = useState(0);

  const [ newClientsAssignedCardMedsi, setNewClientsAssignedCardMedsi ] = useState(0);
  const [ newClientsAssignedCardPogovorim, setNewClientsAssignedCardPogovorim ] = useState(0);

  const [ newClientsBookSessionMedsi, setNewClientsBookSessionMedsi ] = useState(0);
  const [ newClientsBookSessionPogovorim, setNewClientsBookSessionPogovorim ] = useState(0);

  const [ newClientsSuccessBookedSessionMedsi, setNewClientsSuccessBookedSessionMedsi ] = useState(0);
  const [ newClientsSuccessBookedSessionPogovorim, setNewClientsSuccessBookedSessionPogovorim ] = useState(0);

  const [ newClientsTotalSuccessfulSessionsMedsi, setNewClientsTotalSuccessfulSessionsMedsi ] = useState(0);
  const [ newClientsTotalSuccessfulSessionsPogovorim, setNewClientsTotalSuccessfulSessionsPogovorim ] = useState(0);

  const [ crrMedsi, setCrrMedsi ] = useState(0);
  const [ crrPogovorim, setCrrPogovorim ] = useState(0);
  const [ crrFormulaMedsi, setCrrFormulaMedsi ] = useState(0);
  const [ crrFormulaPogovorim, setCrrFormulaPogovorim ] = useState(0);

  const [ regularClientsMedsi, setRegularClientsMedsi ] = useState(0);
  const [ regularClientsPogovorim, setRegularClientsPogovorim ] = useState(0);

  const [ averagePriceMedsi, setAveragePriceMedsi ] = useState(0);
  const [ averagePricePogovorim, setAveragePricePogovorim ] = useState(0);

  const [ averageClientSessionsMedsi, setAverageClientSessionsMedsi ] = useState(0);
  const [ averageClientSessionsPogovorim, setAverageClientSessionsPogovorim ] = useState(0);

  const [ totalPriceMedsi, setTotalPriceMedsi ] = useState(0);
  const [ totalPricePogovorim, setTotalPricePogovorim ] = useState(0);
  
  const [ totalPromocodesMedsi, setTotalPromocodesMedsi ] = useState(0);
  const [ totalPromocodesPogovorim, setTotalPromocodesPogovorim ] = useState(0);
  
  const [ totalDiscountSumMedsi, setTotalDiscountSumMedsi ] = useState(0);
  const [ totalDiscountSumPogovorim, setTotalDiscountSumPogovorim ] = useState(0);
  
  const [ totalDiscountedPriceMedsi, setTotalDiscountedPriceMedsi ] = useState(0);
  const [ totalDiscountedPricePogovorim, setTotalDiscountedPricePogovorim ] = useState(0);

  const [ totalSuccessPayinsMedsi, setTotalSuccessPayinsMedsi ] = useState(0);
  const [ totalSuccessPayinsPogovorim, setTotalSuccessPayinsPogovorim ] = useState(0);


  const formatDateTime = ( dt: string ):string => dt.split('.')[ 0 ].split('T')[0];

  function setDates( form: any ) {

    let start = form.startDate;
    let end = form.endDate;

    if ( typeof start !== 'string' ) start = start.toISOString();
    if ( typeof end   !== 'string' ) end   = end.toISOString();

    setStartDate( start );
    setEndDate( end );

  }

  async function getStatistics() {

    try {

      !isLoading && setIsLoading( true );

      const apiUrl = (

        `${ API_BASE_URL_ADMIN }/${ API_URL.clientsStatistics }`
        +`?startDate=${ formatDateTime( startDate ) }`
        +`&endDate=${ formatDateTime( endDate ) }`

      );

      const { json } = await httpClient( apiUrl, { method: 'GET' });

      if ( !json ) {
        throw new Error( 'Произошла ошибка при получении данных' );
      }

      const medsiStats = json.medsi;
      const pogovorimStats = json.pogovorim;

      console.log({ medsiStats, pogovorimStats })

      setTotalClientsMedsi( medsiStats.totalClients );
      setTotalClientsPogovorim( pogovorimStats.totalClients );

      setTotalSessionsMedsi( medsiStats.totalSessions );
      setTotalSessionsPogovorim( pogovorimStats.totalSessions );

      setFirstTimeClientMedsi( medsiStats.firstTimeClient );
      setFirstTimeClientPogovorim( pogovorimStats.firstTimeClient );

      setNewClientsMedsi( medsiStats.newClients );
      setNewClientsPogovorim( pogovorimStats.newClients );

      setNewClientsAssignedCardMedsi( medsiStats.newClientsAssignedCard );
      setNewClientsAssignedCardPogovorim( pogovorimStats.newClientsAssignedCard );

      setNewClientsBookSessionMedsi( medsiStats.newClientsBookSession );
      setNewClientsBookSessionPogovorim( pogovorimStats.newClientsBookSession );

      setNewClientsSuccessBookedSessionMedsi( medsiStats.newClientsSuccessBookedSession );
      setNewClientsSuccessBookedSessionPogovorim( pogovorimStats.newClientsSuccessBookedSession );

      setNewClientsTotalSuccessfulSessionsMedsi( medsiStats.newClientsTotalSuccessfulSessions );
      setNewClientsTotalSuccessfulSessionsPogovorim( pogovorimStats.newClientsTotalSuccessfulSessions );

      setCrrMedsi( medsiStats.crr );
      setCrrPogovorim( pogovorimStats.crr );

      setCrrFormulaMedsi( medsiStats.crrFormula );
      setCrrFormulaPogovorim( pogovorimStats.crrFormula );

      setRegularClientsMedsi( medsiStats.regularClients );
      setRegularClientsPogovorim( pogovorimStats.regularClients );

      setAveragePriceMedsi( medsiStats.averagePrice );
      setAveragePricePogovorim( pogovorimStats.averagePrice );

      setAverageClientSessionsMedsi( medsiStats.averageClientSessions );
      setAverageClientSessionsPogovorim( pogovorimStats.averageClientSessions );

      setTotalPriceMedsi( medsiStats.totalPrice );
      setTotalPricePogovorim( pogovorimStats.totalPrice );

      setTotalPromocodesMedsi( medsiStats.totalPromocodes );
      setTotalPromocodesPogovorim( pogovorimStats.totalPromocodes );

      setTotalDiscountSumMedsi( medsiStats.totalDiscountSum );
      setTotalDiscountSumPogovorim( pogovorimStats.totalDiscountSum );

      setTotalDiscountedPriceMedsi( medsiStats.totalDiscountedPrice );
      setTotalDiscountedPricePogovorim( pogovorimStats.totalDiscountedPrice );

      setTotalSuccessPayinsMedsi( medsiStats.totalSuccessPayins );
      setTotalSuccessPayinsPogovorim( pogovorimStats.totalSuccessPayins );

      setIsLoading( false );

    } catch ( err ) {

      alert( err );

    }

  }

  useEffect(() => { getStatistics(); }, [ startDate, endDate ]);

  return (

    <Root>

      <Form onSubmit = { setDates }>

        <div>

          <DateTimeInput

            label="Начало промежутка"
            source="startDate"
            style={{ marginRight: '10px' }}
            defaultValue={startDate}

          />

          <DateTimeInput

            label="Конец промежутка"
            source="endDate"
            defaultValue={endDate}
            style={{ marginRight: '10px' }}

          />

          <ApplyDateButton>Показать</ApplyDateButton>

        </div>  

      </Form>

      { isLoading ? "Загружаю..." :
        
        <Fragment>

          <StatHead />

          <StatRow

            title = "Всего клиентов (у кого есть хотя бы одна успешная сессия)"
            medsi = { totalClientsMedsi }
            pogovorim = { totalClientsPogovorim }

          />

          <StatRow

            title = "Всего сессий"
            medsi = { totalSessionsMedsi }
            pogovorim = { totalSessionsPogovorim }

          />

          <StatRow
          
            middleAllValue
            title = "Ср. количество сессий на клиента "
            medsi = { averageClientSessionsMedsi }
            pogovorim = { averageClientSessionsPogovorim }

          />

          <StatRow

            title = "Новых пациентов зарегистрировалось"
            medsi = { newClientsMedsi }
            pogovorim = { newClientsPogovorim }

          />

          <StatRow

            title = "Новых пациентов, привязавших карту"
            medsi = { newClientsAssignedCardMedsi }
            pogovorim = { newClientsAssignedCardPogovorim }

          />

          <StatRow

            title = "Новых пациентов, пытавшихся забронировать сессию"
            medsi = { newClientsBookSessionMedsi }
            pogovorim = { newClientsBookSessionPogovorim }

          />

          <StatRow

            title = "Новых пациентов, успешно забронировавших сессию"
            medsi = { newClientsSuccessBookedSessionMedsi }
            pogovorim = { newClientsSuccessBookedSessionPogovorim }

          />

          <StatRow

            title = "Новых клиентов (первая сессия на платформе в этом периоде)"
            medsi = { firstTimeClientMedsi }
            pogovorim = { firstTimeClientPogovorim }

          />

          <StatRow

            title = "Кол-во успешных сессий у новых пациентов"
            medsi = { newClientsTotalSuccessfulSessionsMedsi }
            pogovorim = { newClientsTotalSuccessfulSessionsPogovorim }

          />

          <StatRow

            title = "Постоянных клиентов"
            medsi = { regularClientsMedsi }
            pogovorim = { regularClientsPogovorim }

          />

          <StatRow

            inPercent
            middleAllValue
            title = "CRR"
            medsi = { crrMedsi }
            pogovorim = { crrPogovorim }

          />

          <StatRow

            withoutAllValue
            title = "CRR (формула)"
            medsi = { crrFormulaMedsi }
            pogovorim = { crrFormulaPogovorim }

          />

          <StatRow

            title = "Стоимость всех консультаций"
            medsi = { totalPriceMedsi }
            pogovorim = { totalPricePogovorim }

          />

          <StatRow

            title = "Количество выданных промокодов"
            medsi = { totalPromocodesMedsi }
            pogovorim = { totalPromocodesPogovorim }

          />

          <StatRow

            title = "Промокодов на сумму"
            medsi = { totalDiscountSumMedsi }
            pogovorim = { totalDiscountSumPogovorim }

          />

          <StatRow

            title = "Стоимость всех консультаций за вычетом промокодов"
            medsi = { totalDiscountedPriceMedsi }
            pogovorim = { totalDiscountedPricePogovorim }

          />

          <StatRow

            middleAllValue
            title = "Ср. стоимость сессии"
            medsi = { averagePriceMedsi }
            pogovorim = { averagePricePogovorim }

          />

          <StatRow

            title = "Количество успешных оплат за период"
            medsi = { totalSuccessPayinsMedsi }
            pogovorim = { totalSuccessPayinsPogovorim }

          />

        </Fragment>
      
      }

    </Root>

  );

}

export default ClientsStatistics;

const Root = styled.div`

  max-width: 750px;
  padding: 20px;

`;

const ApplyDateButton = styled.button`

  background-color: #010101;
  border: none;
  color: #fff;
  padding: 16px 15px;
  margin-top: 9px;

`;