import { useEffect, useState } from 'react';
import { Form, NumberInput, SaveButton, TextInput } from 'react-admin';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';
import styled from 'styled-components';

type Currency = {
  id: string;
  name: string;
  rubExchangeRate: number;
}

const Currencies = () => {
  const [data, setData] = useState<Array<Currency>>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCurrency();
  }, []);

  const fetchCurrency = () => {
    const token = localStorage.getItem('token');

    setIsLoading(true);
    fetch(
      API_BASE_URL_ADMIN + API_URL.currencies,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      },
    )
      .then((res) => res.json())
      .then((result) => setData(result))
      .finally(() => {
        setIsLoading(false);
      });
  }

  const updateCurrency = async (currencies: Array<Currency>) => {
    setIsLoading(true);

    await httpClient(API_BASE_URL_ADMIN + API_URL.updateCurrency, {
      method: 'POST',
      body: JSON.stringify({ currencies }),
    });
    
    setIsLoading(false);
    fetchCurrency();
  };

  const handleSubmit = (params: { [id: string]: { name: string; rubExchangeRate: number } }) => {
    const currencies = Object.keys(params).map(id => ({ id, name: params[id].name, rubExchangeRate: params[id].rubExchangeRate }));
    updateCurrency(currencies);
  }

  return (
    <>
      <Root>
        {(isLoading || !data) && <p>Загрузка...</p>}
        <Form onSubmit={handleSubmit}>
          {data && data.map(currency => (
            <div key={currency.id}>
              <TextInput
                label="Название"
                source={`${currency.id}.name`}
                style={{ marginRight: '10px' }}
                defaultValue={currency.name}
              />
              <NumberInput
                label="Курс к рублю"
                source={`${currency.id}.rubExchangeRate`}
                defaultValue={currency.rubExchangeRate}
                style={{ marginRight: '10px' }}
              />
            </div>
          ))}
          <SaveButton label="Изменить" disabled={isLoading} />
        </Form>
      </Root>
    </>
  );
};

export default Currencies;

const Root = styled.div`
  padding: 20px;
`;
