import { API_BASE_URL } from "shared/config";
import { API_URL } from "shared/constants/api-url";
import { httpClient } from "shared/services/http-client";

enum SessionTypes {

  PERSONAL = 'personal',
  GROUP = 'group'

}

interface iBrone {

  patientId: string, 
  availabilityId: string,
  promoCode: string | undefined

}

async function brone( form: iBrone ) {

  const apiUrl = `${API_BASE_URL}/${API_URL.sessionBrone}`;

  const params = {

    patientId: form.patientId,
    availabilityId: form.availabilityId,
    type: SessionTypes.PERSONAL,
    promocode: form.promoCode

  }

  const settings = { 
    
    method: 'POST',
    body: JSON.stringify( params )
  
  }

  try {

    const { json, status } = await httpClient( apiUrl, settings );
    const successBrone = status === 201;

    console.log({ json, status })

    if ( !successBrone ) throw new Error( json?.message || 'Произошла ошибка при получении данных' );

    return successBrone;

  } catch ( err ) {

    console.error(err);
    throw err;

  }
 
}

export {

  brone

}
