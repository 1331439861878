export const LABELS = {
  utmCampaign: 'utm_campaign',
  utmMedium: 'utm_medium',
  utmSource: 'utm_source',
  firstname: 'Имя',
  lastname: 'Фамилия',
  rewardInPercent: '% выплаты',
  isTop: 'ТОП-терапевт',
  isTestTherapist: 'Тестовый',
  isTestPatient: 'Тестовый пациент',
  isMedsi: 'От MEDSI',
  isAdult: 'Взрослый',
  isChildTherapist: 'Детский',
  isSupervisorShort: 'Супервизор',
  isB2B: 'B2B',
  isCardAssigned: 'Прикреплена карта',
  dateOfBirth: 'Дата рождения',
  gender: 'Пол',
  email: 'Почта',
  phone: 'Телефон',
  avatarKey: 'Фотография',
  country: 'Страна',
  city: 'Город',
  password: 'Пароль',
  name: 'Название',
  companyName: 'Имя компании',
  isEmailConfirmed: 'Почта подтверждена',
  languages: 'Языки',
  devoteHoursToPlatform: 'Готов тратить часов на платформу',
  numberOfPatients: 'Количество пациентов в работе',
  workWithCouples: 'Работает с парами',
  workWithFamilies: 'Работает с семьями',
  workWithGroups: 'Работает с группами',
  workWithIndividual: 'Работает с индивидуальными консультациями',
  workWithCheckup: 'Работает с чекапами',
  workWithClinical: 'Работает с клинической психотерапией',
  workWithChildren: 'Работает с детьми',
  workWithLgbt: 'Работает с ЛГБТ',
  workWithReligions: 'Работает с религиозными направленностями',
  workingExperience: 'Стаж работы',
  workingExperienceDescription: 'Описание опыта работы',
  ethnicExperienceDescription: 'Описание опыта работы с этническими группами',
  university: 'Университет',
  speciality: 'Специальность',
  files: 'Файлы',
  organisation: 'Организация',
  course: 'Курс',
  isSupervisor: 'Является супервизором',
  supervisionExperience: 'Стаж работы супервизором',
  recommendationText: 'Кто рекомендует',
  recommendationFiles: 'Файлы рекомендации',
  supervisionFrequency: 'Как часто посещает супервизии',
  supervisionType: 'Тип супервизии',
  supervisionVisitsDuration: 'Сколько посещает супервизии',
  supervisionExperienceDescription: 'Описание опыта супервизии',
  specialisation: 'Специализация',
  therapistProfileDiseases: 'Работаю с',
  therapistProfileExcludedDiseases: 'Не работаю с',
  therapistProfileTherapyMethods: 'Методы терапии',
  about: 'О себе',
  shortAbout: 'Краткое о себе',
  diseasesGroup: 'Группа заболеваний',
  description: 'Описание',
  title: 'Заголовок',
  question: 'Вопрос',
  answer: 'Ответ',
  promocodeAmount: 'Номинал',
  promocodePercentage: 'Процент',
  promocodeFirstUseOnly: 'Только первое использование',
  promocodeQuantity: 'Количество',
  promocodeExpireDate: 'Истечение срока действия',
  price: 'Стоимость консультации',
  contactByPhone: 'Связь по телефону',
  medsiSessionsClients: 'Медси',
  medsiPogovorim: 'Pogovorim',

  workApproach1: 'Подход к работе (направляющий/поддерживающий)',
  workApproach2: 'Подход к работе (мягкий/прямой)',
  workApproach3: 'Подход к работе (неформальный/серьезный)',

  workWithPsychiatristExperience: 'Есть ли опыт работы с психиатром',
  psychiatristCollaboration: 'Сотрудничает ли в настоящее время с психиатром по клиентам',

  workshopExperience: 'Есть ли у вас опыт проведения мастер-классов, тренингов или вебинаров?*',
  workshopDesire: 'Хотите ли вы проводить мастер-классы, вебинары или тренинги совместно с платформой?*',
  blogMaterials: 'Рассматриваете ли вы возможность написания материалов для блога?*',
  specialProjects: 'Заинтересованы ли вы в участии в специальных проектах (лекции, программы, конференции)?*',
  socialsLinks: 'Пожалуйста, поделитесь ссылками на ваши профили в социальных сетях.*',
  promotionSupport: 'Интересует ли вас поддержка в продвижении социальных сетей?*',
  materialsLinks: 'Пожалуйста, поделитесь ссылками на ваши материалы (статьи, курсы, книги и т.д.).*',
  companyRole: 'Должность',
  companyGroup: 'Группа',
};
