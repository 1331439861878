import {
  DateField,
  Show,
  TabbedShowLayout,
  TextField,
  BooleanField,
  Tab,
  Loading,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { CompaniesShowActions } from './ui/actions';
import AddPromocodesForm from './ui/add-promocodes-form';
import Owner from './ui/owner';
import CompanyPatients from './ui/patients';
import Promocodes from './ui/promocodes';
import Statistic from './ui/statistic';
import UploadPatients from './ui/upload';

export const CompaniesShow = () => {
  const { id } = useParams();

  if (!id) {
    return <Loading />;
  }

  return (
    <Show actions={<CompaniesShowActions />}>
      <TabbedShowLayout>
        <Tab label="Детали компании">
          <TextField source="id" label="ID компании" sortable={false} />
          <TextField
            source="ownerId"
            label="ID владельца компании"
            sortable={false}
          />
          <TextField source="name" label="Имя компании" sortable={false} />
          <BooleanField source="isArchive" label="В архиве" sortable={false} />
          <DateField
            source="createdAt"
            label="Дата добавления"
            sortable={false}
          />
        </Tab>
        <Tab label="Статистика">
          <Statistic />
        </Tab>
        <Tab label="Промокоды">
          <Promocodes />
        </Tab>
        <Tab label="Владелец компании">
          <Owner />
        </Tab>
        <Tab label="Добавить промокоды">
          <AddPromocodesForm />
        </Tab>
        <Tab label="Сотрудники">
          <CompanyPatients companyId={id} />
        </Tab>
        <Tab label="Загрузка сотрудников">
          <UploadPatients />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
